import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "24",
  height: "24",
  fill: "none",
  viewBox: "0 0 24 24"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "currentColor",
      "fill-rule": "evenodd",
      d: "M8.506 12.478a3.49 3.49 0 0 0 3.485 3.489 3.488 3.488 0 0 0 0-6.978 3.49 3.49 0 0 0-3.485 3.489m1.394 0c0-1.154.938-2.093 2.09-2.093 1.153 0 2.092.94 2.092 2.093a2.095 2.095 0 0 1-2.091 2.093A2.095 2.095 0 0 1 9.9 12.478",
      "clip-rule": "evenodd"
    }, null, -1),
    _createElementVNode("path", {
      fill: "currentColor",
      "fill-rule": "evenodd",
      d: "M14.389 21.884c.195.046.39.074.585.074.39 0 .771-.102 1.125-.298l1.607-.92a2.4 2.4 0 0 0 1.153-1.499 2.44 2.44 0 0 0-.242-1.87c-.279-.474-.344-.902-.186-1.172s.558-.428 1.106-.428A2.47 2.47 0 0 0 22 13.306v-1.637a2.47 2.47 0 0 0-2.463-2.466c-.548 0-.948-.158-1.106-.428s-.083-.697.186-1.172a2.473 2.473 0 0 0-.91-3.368l-1.6-.92c-1.059-.633-2.443-.27-3.075.809l-.103.176c-.548.95-1.3.95-1.85 0l-.11-.186c-.633-1.07-2.017-1.432-3.068-.809l-1.607.921A2.4 2.4 0 0 0 5.14 5.724a2.48 2.48 0 0 0 .242 1.87c.279.474.344.902.186 1.172s-.558.428-1.106.428A2.47 2.47 0 0 0 2 11.659v1.638a2.47 2.47 0 0 0 2.463 2.465c.548 0 .948.158 1.106.428s.083.698-.186 1.172a2.473 2.473 0 0 0 .91 3.368l1.6.921c.51.307 1.114.39 1.7.233a2.23 2.23 0 0 0 1.366-1.042l.102-.177c.549-.95 1.302-.95 1.85 0l.111.186c.307.512.79.874 1.367 1.033m-.27-1.926c-.53-.921-1.301-1.442-2.128-1.442s-1.599.53-2.129 1.442l-.102.177a.88.88 0 0 1-.53.4.82.82 0 0 1-.64-.093L6.98 19.52a1.1 1.1 0 0 1-.492-.65 1.06 1.06 0 0 1 .102-.81c.53-.912.595-1.851.186-2.568-.409-.716-1.255-1.126-2.314-1.126a1.07 1.07 0 0 1-1.069-1.07V11.66c0-.595.474-1.07 1.069-1.07 1.06 0 1.905-.409 2.314-1.125.409-.717.344-1.656-.186-2.568a1.076 1.076 0 0 1 .4-1.46l1.608-.93a.853.853 0 0 1 1.152.297l.102.186c.53.92 1.301 1.442 2.128 1.442s1.599-.521 2.129-1.442l.102-.177a.855.855 0 0 1 1.17-.307l1.609.921c.241.149.418.372.492.651.075.28.037.568-.102.81-.53.911-.595 1.851-.186 2.567.41.717 1.255 1.126 2.314 1.126a1.07 1.07 0 0 1 1.07 1.07v1.637c0 .596-.475 1.07-1.07 1.07-1.06 0-1.905.41-2.314 1.126s-.344 1.656.186 2.568a1.076 1.076 0 0 1-.4 1.46l-1.607.93a.82.82 0 0 1-.632.084.82.82 0 0 1-.52-.39z",
      "clip-rule": "evenodd"
    }, null, -1)
  ])))
}
export default { render: render }